<template>
  <div class="changepwd-container">
    <el-row class="title-container">
      <el-col :span="20">
        <div class="title-text"> {{ $t('accountManagement.sidebarPassword') }}</div>
      </el-col>
      <el-col :span="4" class="title-right">
        <el-button
          type="success"
          :disabled="!hasEdit"
          @click="submitForm('pwdInfoForm')"
        >
          {{ $t('general.Save') }}
        </el-button>
      </el-col>
    </el-row>
    <div class="form-container">
      <el-form 
        ref="pwdInfoForm"
        :model="pwdInfoForm"
        :rules="pwdRules"
        class="changepwd-form"
      >
        <el-form-item 
          :label="$t(`accountManagement.oldPassword`)" 
          prop="oldPwd" 
          class="pwd-container"
        >
          <el-input 
            ref="password1"
            v-model="pwdInfoForm.oldPwd"
            :type="passwordType1"
            @change="hasEdit=true"
          />
          <span 
            class="show-pwd"
            @click="showPwd(1)"
          >
            <em :class="passwordType1 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
          </span>
        </el-form-item>
        <el-form-item 
          :label="$t(`accountManagement.newPassword`)"
          prop="newPwd" 
          class="pwd-container"
        >
          <el-input 
            ref="password2"
            v-model="pwdInfoForm.newPwd"
            :type="passwordType2"
            @change="hasEdit=true"
          />
          <span 
            class="show-pwd"
            @click="showPwd(2)"
          >
            <em :class="passwordType2 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
          </span>
          <div class="pwd-notice">
            {{ $t('accountManagement.PasswordRule') }}
          </div>
        </el-form-item>
        <el-form-item 
          :label="$t(`accountManagement.confirmNewPassword`)" 
          prop="confirmNewPwd" 
          class="pwd-container"
        >
          <el-input 
            ref="password3"
            v-model="pwdInfoForm.confirmNewPwd"
            :type="passwordType3"
            @change="hasEdit=true" 
          />
          <span 
            class="show-pwd"
            @click="showPwd(3)"
          >
            <em :class="passwordType3 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
          </span>
        </el-form-item>
      </el-form>
    </div>
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex'
import { MemberRole, SuccessMessage } from '@/Mixins'
import { updateUser } from '@/api/member'

export default {
  name: 'ChangePwd',
  mixins: [MemberRole, SuccessMessage],
  data () {
    const validatePassword = (rule, value, callback) => {
      if (value.length > 0 && value.length < 8) {
        callback(new Error(this.$t('RuleMsg.PasswordLimit')))
      } else if (this.pwdInfoForm.oldPwd === value) {
        callback(new Error(this.$t('RuleMsg.DifferentNewPassword')))
      } else {
        callback()
      }
    }

    const checkRepeatPwd = (rule, value, callback) => {
      if (value !== this.pwdInfoForm.newPwd) {
        callback(new Error(this.$t('RuleMsg.PasswordNotSame')))
      } else {
        callback()
      }
    }
    return {
      pwdInfoForm: {
        oldPwd: '',
        newPwd: '',
        confirmNewPwd: ''
      },
      pwdRules: {
        newPwd: [
          { 
            required: true,
            pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^[\w!@#$%^&*()+|{}\[\]`~\-'";:/?.\\>,<]{8,20}$/,
            message: this.$t('RuleMsg.Invalid') + this.$t('RuleMsg.Password'),
            trigger: 'blur'
          },
          { validator: validatePassword, trigger: 'blur' }
        ],
        confirmNewPwd: [
          { required: true, message: this.$t('RuleMsg.InputRepeatPwd'), trigger: 'blur' },
          { validator: checkRepeatPwd, trigger: 'blur' }
        ],
        oldPwd: [
          { required: true, message: this.$t('RuleMsg.PleaseInput') + this.$t('RuleMsg.Password'), trigger: 'blur' }
        ]
      },
      hasEdit: false,
      below_organization_ids: [],
      passwordType1: 'password',
      passwordType2: 'password',
      passwordType3: 'password'
    }
  },
  computed: {
    ...mapGetters(['memberId'])
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData () {
      if (this.isSubadmin) {
        this.getBelowOrgs()
      }
    },
    async fetchUpdateMember() {
      let below_orgs = []
      if (this.below_organization_ids.length) {
        below_orgs = this.below_organization_ids
      }
      const newObj = {
        password: this.pwdInfoForm.newPwd,
        old_password: this.pwdInfoForm.oldPwd,
        below_organization_ids: below_orgs
      }
      try {
        await updateUser(this.memberId, newObj)
        this.showSuccessMessage(this.$t('Notify.Updated'))
      } catch (error) {
        console.error(error)
      }
    },
    getBelowOrgs() {
      const idsArray = []
      this.belowOrgList.forEach((item) => {
        idsArray.push(item.id)
      })
      this.below_organization_ids = idsArray
    },
    
    showPwd(value) {     
      if (value === 1) {
        if (this.passwordType1 === 'password') {
          this.passwordType1 = ''
        } else {
          this.passwordType1 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password1.focus()
        })
      } else if (value === 2) {
        if (this.passwordType2 === 'password') {
          this.passwordType2 = ''
        } else {
          this.passwordType2 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password2.focus()
        })
      } else {
        if (this.passwordType3 === 'password') {
          this.passwordType3 = ''
        } else {
          this.passwordType3 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password3.focus()
        })
      }
    },
    submitForm(pwdInfoForm) {
      this.$refs[pwdInfoForm].validate((valid) => {
        if (valid) {
          this.fetchUpdateMember()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.changepwd-container{
  width: 100%;
  padding: 0 20px;
  .title-container{
    margin: 10px 0;
    .title-text{
      font-size: 20px;
      line-height: 2;
      text-align: left;
    }
    .title-right{
      text-align: end;
      .el-button {
        margin-bottom: 5px;
        padding: 10px 50px;
        margin-bottom: 5px;
      }
    }
  }
  .form-container{
    background-color: #fff;
    .changepwd-form{
      padding: 50px 0;
      margin: 0 auto;
      width: 860px;
    }
    .pwd-container{
      position: relative;
      .show-pwd {
        position: absolute;
        right: 10px;
        top: 0px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
      .pwd-notice{
        text-align: left;
        color: $info;
        font-size: 13px;
      }
    }
  }
}
</style>
